import React from 'react';
import Router from './Router'

class App extends React.Component {
  render () {
    return (
      <Router />
    )
  }
}

export default App;
