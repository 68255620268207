import React, {Suspense} from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { routerConfig } from './routerConfig';
import Index from './pages/Index';

function router() {
  const routes = routerConfig.map(({ path, component: Component }) => {
    return <Route key={path} path={path} exact component={Component} />
  })

  return (
    <Suspense fallback={<Index />}>
      <Router>
        <Switch>
          {routes}
          <Redirect to="/404" />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default router;