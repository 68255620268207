import React, { Component } from 'react'

import logo from '../logo.svg';
import '../App.css';

export default class Index extends Component {
  render () {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>
    );
  }
}