import {lazy} from 'react';

// use token generated by jxb wechat miniprogram
const routerConfig_jxb = [
  { path: "/j/e/:token", component: lazy(() => import("./pages/jxb/entry/Entry")) },
]

// test/demo pages
const routerConfig_test = [
  { path: "/test/box/intercom", component: lazy(() => import("./pages/test/box/TestBoxIntercom")) },
  { path: "/test/miniprogram/opentag", component: lazy(() => import("./pages/test/miniprogram/TestOpenMiniprogram")) },
  { path: "/test/antdesign/mobile", component: lazy(() => import("./pages/test/antdesign/TestAntDesignMobile")) },
]

/* 18gps 硬件对接 */
const routerConfig_18gps = [
  { path: "/18gps/overseas", component: lazy(() => import("./pages/18gps/index/Gps18Overseas")) },
  { path: "/18gps/overseas/login", component: lazy(() => import("./pages/18gps/login/Login")) },
]

export const routerConfig = [
  /* 主页，同时承担加载效果 */
  { path: "/", component: lazy(() => import("./pages/Index")) },

  /* 错误处理 */
  { path: "/404", component: lazy(() => import("./pages/PageNotFound")) },

  /* 登录 */
  { path: "/login-by-tel", component: lazy(() => import("./pages/login/LoginByTel")) },

  { path: '/menus', component: lazy(() => import("./pages/menus")) },

  /* 出场单 */
  /* 机主 */
  { path: "/transport/list", component: lazy(() => import("./pages/transport")) },
  /* 机手 */
  { path: "/transport/list/driver", component: lazy(() => import("./pages/transport")) },
  { path: "/transport/detail/:id", component: lazy(() => import("./pages/transport/TransportDetail")) },
  { path: "/p/b/q/:id", component: lazy(() => import("./pages/project/QuitBillDetail")) },

  /* 机器 */
  /* 机器退场详情 */
  { path: "/m/q/:code", component: lazy(() => import("./pages/machine/MacQuitDetail")) },

  /* 结算单 */
  { path: "/statement/list", component: lazy(() => import("./pages/statement")) },
  { path: "/statement/detail/:id", component: lazy(() => import("./pages/statement/StatementDetail")) },

  /* 商城 */
  { path: "/mall/pay/4mac/detail", component: lazy(() => import("./pages/mall/MallPay4Mac")) },
  /* bss订单 */
  { path: "/bss/order", component: lazy(() => import("./pages/bss/order/index")) },

  /* 注册邀请 */
  { path: "/register/invitation", component: lazy(() => import("./pages/register")) },

  /* 分享 */
  { path: "/s/p/:shareId", component: lazy(() => import("./pages/share/ProjectMonthReport")) },
  { path: "/s/m/:shareId", component: lazy(() => import("./pages/share/MachineInfo")) },

  /* Cmsv6Player 视频播放页面 */
  { path: "/v/:params", component: lazy(() => import("./pages/video/Cmsv6PlayerPage")) },
  { path: "/vp/:params", component: lazy(() => import("./pages/video/VideoPlayback")) },

  /* 油料管理 */
  { path: "/oilApplyDetail/:id", component: lazy(() => import("./pages/oil/oilapply/OilApplyDetail")) },

  ...routerConfig_jxb,
  ...routerConfig_18gps,
  ...routerConfig_test,
]